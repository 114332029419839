import React, { memo, useContext } from 'react'
import {
  useStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './style'
import { CheckIcon } from 'components'
import { JOB_REQUIREMENT_STEPS } from '../constant'
import { Typography, Button, Grid } from '@mui/material'
import { AccordionControllContext } from 'context/AccordionControll'

interface Props {
  title: string
  description?: React.ReactNode
  children: React.ReactNode
  required?: boolean
  disableNextStep?: boolean
  preview?: string[]
  RenderPreview?: any
}

const ExpansionPanel = ({
  title,
  description,
  children,
  required,
  disableNextStep,
  preview,
  RenderPreview
}: Props): React.ReactElement => {
  const classes = useStyles()
  const { expanded, setExpanded, handleChangeExpanded } = useContext(
    AccordionControllContext
  )
  const handleNext = () => {
    if (title === JOB_REQUIREMENT_STEPS[6]) {
      setExpanded(false)
    } else {
      const currentIndex = JOB_REQUIREMENT_STEPS.indexOf(title)
      setExpanded(JOB_REQUIREMENT_STEPS[currentIndex + 1])
    }
  }
  const isExpanded = expanded === title
  return (
    <Accordion
      square
      expanded={isExpanded}
      onChange={handleChangeExpanded(title)}
    >
      <AccordionSummary>
        <CheckIcon filled disabled={disableNextStep} size={18} />
        <Grid>
          <Typography
            display='block'
            variant='formLabel'
            marginLeft={'18px'}
            marginBottom={1}
          >
            {title}
          </Typography>
          {isExpanded && (
            <Typography variant='body2' marginLeft={'18px'}>
              {description}
            </Typography>
          )}
          {!isExpanded &&
            (disableNextStep ? (
              <Typography variant='body2' marginLeft={'18px'}>
                {description}
              </Typography>
            ) : (
              <>
                {preview &&
                  preview.map((data: string, index: number) => (
                    <Typography
                      variant='body2'
                      key={`item_${index}`}
                      className={classes.displayText}
                    >
                      {data}
                    </Typography>
                  ))}
                {RenderPreview && <RenderPreview />}
              </>
            ))}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <Grid container justifyContent='flex-end'>
          {!required && (
            <Button
              variant='text'
              className={classes.longButton}
              onClick={handleNext}
            >
              <Typography variant='button'> Skip</Typography>
            </Button>
          )}
          <Button
            variant='contained'
            className={classes.longButton}
            disabled={disableNextStep}
            onClick={handleNext}
          >
            Next
          </Button>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(ExpansionPanel)
