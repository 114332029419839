import React, { useContext } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import useStyles from './style'
import { Color, ErrorIcon } from 'components'
import { MicroServiceContext } from 'context/MicroService'

const ImpersonatorBanner = (): React.ReactElement => {
  const classes = useStyles()

  const { currentAccount } = useContext(MicroServiceContext)
  const handleLogout = () => {}

  return (
    <Grid
      container
      className={classes.container}
      justifyContent='center'
      padding='12px'
    >
      <ErrorIcon filled />
      <Grid item xs={5} marginLeft='18px'>
        <Typography variant='formLabel' color={Color.black87}>
          Super admin view
        </Typography>
        <Typography color={Color.black87} variant='body2'>
          You are viewing this page as if you are a staff member of
          <span className={classes.employerName}>
            {' '}
            {currentAccount.employer.name}
          </span>
          , but be aware that you will not be able to save any changes to the
          assessment.
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Button
          onClick={() => handleLogout()}
          variant='outlined'
          className={classes.button}
        >
          Exit session
        </Button>
      </Grid>
    </Grid>
  )
}

export default ImpersonatorBanner
